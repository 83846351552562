<template>
  <PageCover :active-path="'album'"
             image="/img/gallery/laser-maze-2.webp"
             page-title="ALBUM">
  </PageCover>
  <ul class="nav justify-content-center mt-5">
    <li
      v-for="(item, index) in sections"
      :key="index"
      class="nav-item">
      <button
        @click="selectedSection = item.id"
        class="nav-link btn mx-2 photo-section"
        :class="selectedSection === item.id ? 'btn-blue' : 'btn-link'" >
        <strong>
          {{item.name}}
        </strong>
      </button>
    </li>
  </ul>
  <div class="mb-5 pb-5 mt-5">
    <div class="images" v-viewer="viewerOptions">
      <img v-for="src in photos"
           width="500"
           height="333"
           class="photo img-fluid"
           :key="'img/gallery/thumbs/' + src"
           :data-source="'img/gallery/' + src"
           :src="'img/gallery/thumbs/' + src">
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import VueViewer from 'v-viewer';
import Footer from '../components/Footer.vue';
import PageCover from '../components/PageCover.vue';

window.app.use(VueViewer);

export default {
  name: 'Album',
  components: {
    PageCover,
    Footer,
  },
  mounted() {
    if (this.$route.params.section) {
      this.selectedSection = this.$route.params.section;
    }
  },
  data() {
    return {
      viewerOptions: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: false,
        zoomable: false,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: false,
        keyboard: false,
        url: 'data-source',
      },
      selectedSection: 'all',
      sections: [
        {
          name: 'Toate',
          id: 'all',
        },
        {
          name: 'Laser tag',
          id: 'laser-tag',
        },
        {
          name: 'Petreceri',
          id: 'party',
        },
        {
          name: 'Karaoke room',
          id: 'karaoke',
        },
        {
          name: 'Laser maze',
          id: 'maze',
        },
        {
          name: 'Racing simulator',
          id: 'racing',
        },
      ],
      initialPhotos: [
        'racing-sim.webp',
        'birthday-party.webp',
        'bunker-arena-5.webp',
        'bunker-arena-2.webp',
        'bunker-arena-3.webp',
        'bunker-arena-4.webp',
        'bunker-arena-6.webp',
        'bunker-arena.webp',
        'karaoke-singing.webp',
        'karaoke-2.webp',
        'karaoke-behringer.webp',
        'karaoke-mic.webp',
        'karaoke-mics.webp',
        'karaoke-mixer.webp',
        'karaoke-overview-2.webp',
        'karaoke-overview.webp',
        'karaoke-singing-2.webp',
        'karaoke-singing-3.webp',
        'karaoke.webp',
        'laser-maze-entrance.webp',
        'laser-maze-2.webp',
        'laser-maze-3.webp',
        'laser-maze.webp',
        'laser-tag.webp',
        'laser-tag-1.webp',
        'laser-tag-2.webp',
        'laser-tag-3.webp',
        'laser-tag-4.webp',
        'laser-tag-5.webp',
        'laser-tag-6.webp',
        'laser-tag-7.webp',
        'laser-tag-8.webp',
        'laser-tag-9.webp',
        'laser-tag-10.webp',
        'laser-tag-11.webp',
        'laser-tag-12.webp',
        'nintendo-switch.webp',
        'playstation-2.webp',
        'playstation-3.webp',
        'playstation.webp',
        'racing-sim-2.webp',
        'racing-sim-3.webp',
        'racing-sim-4.webp',
        'racing-sim-5.webp',
        'racing-sim-6.webp',
      ],
    };
  },
  computed: {
    photos() {
      const p = this.initialPhotos;
      switch (this.selectedSection) {
        default:
        case 'all':
          return p.sort(() => Math.random() - 0.5);
        case 'laser-tag':
        case 'karaoke':
        case 'maze':
        case 'racing':
          return this.initialPhotos.filter((item) => item.indexOf(this.selectedSection) >= 0);
        case 'party':
          return this.initialPhotos.filter((item) => item.indexOf(this.selectedSection) >= 0
              || item.indexOf('laser-tag.webp') >= 0
              || item.indexOf('playstation-3.webp') >= 0
              || item.indexOf('playstation.webp') >= 0);
      }
    },
  },
};
</script>

<style scoped>
  @import '~viewerjs/dist/viewer.css';

  .photo {
    cursor: pointer;
  }

  .btn-link {
    color: white;
  }

  .photo-section:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 3px #1cbdef, 0 0 4px 2px #1cbdef;
  }

  .nav-link {
    color: white;
  }
</style>

<style lang="scss">
  .viewer-navbar {
    background-color: rgba(0, 0, 0, .8);
  }

   .viewer-backdrop {
     background-color: rgba(0, 0, 0, .85);
   }

  .viewer-reset,
  .viewer-play {
    display: none;
  }

  .viewer-toolbar > ul {
    transform: scale(1.5);
  }

  .viewer-close {
    background-color: rgba(28, 189, 239, 1);

    &:hover {
      background-color: rgba(28, 189, 239, .7);
    }
  }

  .viewer-toolbar > ul > li::before {
    margin: 2px;
    background-color: rgba(28, 189, 239, 1);
    border-radius: 10px;
  }
</style>
